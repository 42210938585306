import React, {useEffect, useState} from 'react';
import {
    CBadge,
    CButton,
    CCard,
    CCardBody, CCardHeader, CFormGroup,
    CInput, CInputFile, CLabel,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle,
    CNav,
    CNavItem,
    CNavLink, CSelect,
    CTabContent, CTabPane,
    CTabs
} from "@coreui/react";
import {getApi} from "../apis/backendApis";
import {
    ClienteAnexoForm,
    SituacaoClienteObjContratoList, SituacaoList,
    TipoAnexoList,
} from "../pages/cadastros/cliente";
import CIcon from "@coreui/icons-react";


interface Props {
    idCliente: number,
    showModal: boolean,
    onClose: () => void,
}

const ModalDetalhesCliente = (props: Props) => {

    const [dataCliente, setDataCliente] = useState<any>([]);
    const [listProjetoRedmine, setListProjetoRedmine] = useState([]);

    useEffect(() => {
        let api = getApi();
        api.get(`cliente/get/` + props.idCliente).then((response) => {
            setDataCliente(response.data);
        });

        api.get(`cliente/projetos`,{
            params: {}
        }).then((response)=>{
            let dataList = response.data;
            setListProjetoRedmine(dataList);

        }).finally(()=>{

        });
    },[]);

    const generateLink = (id:number):string=>{
        let api = getApi();
        return `${api.defaults.baseURL}cliente/download_document/${id}`;
    }

    return(
            <CModal
                show={props.showModal}
                onClosed={props.onClose}
                backdrop={true}
                color="info"
                size={'xl'}
            >
                <CModalHeader closeButton>
                    <CModalTitle>Detalhes Cliente</CModalTitle>
                </CModalHeader>
                <CModalBody  style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                    <div className="p-2">
                        <div>
                            <CTabs>
                                <CNav variant="tabs">
                                    <CNavItem>
                                        <CNavLink>
                                            Geral
                                        </CNavLink>
                                    </CNavItem>
                                    <CNavItem>
                                        <CNavLink>
                                            Endereço/Contato
                                        </CNavLink>
                                    </CNavItem>
                                    <CNavItem>
                                        <CNavLink>
                                            Anexos
                                        </CNavLink>
                                    </CNavItem>
                                    <CNavItem>
                                        <CNavLink>
                                            Configurações
                                        </CNavLink>
                                    </CNavItem>
                                    <CNavItem>
                                        <CNavLink>
                                            Objetos de Contrato
                                        </CNavLink>
                                    </CNavItem>
                                </CNav>
                                <CTabContent>
                                    <CTabPane>
                                        <CCard>
                                            <CCardBody>
                                                <CFormGroup>
                                                    <CFormGroup className={'row'}>
                                                        <CLabel className={'col-12 font-weight-bold'} htmlFor="pessoa">Pessoa:</CLabel>
                                                        <CLabel className={'col-12'}>{dataCliente?.pessoa?.nome}
                                                        </CLabel>
                                                    </CFormGroup>
                                                    <CFormGroup className={'row'}>
                                                        <CLabel className={'col-12 font-weight-bold'} htmlFor="situacao">Situação:</CLabel>
                                                        <CLabel className={'col-12'} name="situacao" id="situacao" >{SituacaoList.findSelectValue(dataCliente?.situacao)?.label}
                                                        </CLabel>
                                                    </CFormGroup>
                                                    <CFormGroup className={'row'}>
                                                        <CLabel className={'col-12 font-weight-bold'} htmlFor="dataHomologacao">Data Homologação:</CLabel>
                                                        <CLabel className={'col-12'} type="date" id="dataHomologacao" name="dataHomologacao">
                                                            {dataCliente?.dataHomologacao ? new Date(dataCliente.dataHomologacao).toLocaleDateString('pt-BR') : ''}
                                                        </CLabel>
                                                    </CFormGroup >
                                                    <CFormGroup className={'row'}>
                                                        <CLabel className={'col-12 font-weight-bold'} htmlFor="dataHomologacao">CNPJ:</CLabel>
                                                        <CLabel className={'col-12'} type="cnpj" id="cnpj" name="cnpj"> {dataCliente?.pessoa?.cpfCnpj} </CLabel>
                                                    </CFormGroup>
                                                </CFormGroup>
                                            </CCardBody>
                                        </CCard>
                                    </CTabPane>
                                    <CTabPane>
                                        <div>
                                            <CLabel className={'h5 mt-1'}>
                                                Endereço(s):
                                            </CLabel>
                                        </div>
                                        {dataCliente?.pessoa?.pessoaEndereco && dataCliente?.pessoa?.pessoaEndereco.map((endereco: any, index: number) => (
                                            <CCard key={index} className="mb-2 ">
                                                <div className="row align-items-start" style={{fontSize: '16px'}}>
                                                    <div className="col-auto d-flex align-items-center pr-0 m-1">
                                                        <CIcon
                                                            className="mr-2"
                                                            name={'cilHome'}
                                                            size="xl"
                                                        />
                                                    </div>
                                                    <div className="col d-flex mt-2 flex-column">
                                                        <div className="d-flex align-items-center">
                                                            <CLabel className="mb-0">
                                                                {endereco?.bairro}, {endereco?.logradouro} {endereco?.numero} {endereco?.complemento}
                                                            </CLabel>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <CLabel className="mb-0">
                                                                CEP {endereco?.cidade?.cep} - {endereco?.cidade?.estado} - {endereco?.cidade?.nome}
                                                            </CLabel>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CCard>
                                        ))}
                                        <div className={'border-top mt-3'}>
                                            <CLabel className={'h5 mt-1'}>
                                                Contato(s):
                                            </CLabel>
                                        </div>
                                        {dataCliente?.pessoa?.pessoaContato && dataCliente?.pessoa?.pessoaContato.map((contato: any, index: number) => (
                                            <CCard key={index} className="mb-2">
                                                <div className="row align-items-start" style={{fontSize: '16px'}}>
                                                    <div className="col-auto d-flex align-items-center pr-0 m-1">
                                                        <CIcon
                                                            className="mr-2"
                                                            name={contato.tipo === 1 ? 'cilEnvelopeClosed' : 'cilPhone'}
                                                            size="xl"
                                                        />
                                                    </div>
                                                    <div className="col mt-2 d-flex flex-column">
                                                        <div className="d-flex align-items-center">
                                                            <CLabel className="mb-0">
                                                                {contato?.contato}
                                                            </CLabel>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CCard>
                                        ))}
                                    </CTabPane>
                                    <CTabPane>
                                        {dataCliente?.clienteAnexo && dataCliente?.clienteAnexo.map((anexo: ClienteAnexoForm, index: number) => (
                                            <CCard key={index}>
                                                <CCardBody>
                                                    <CCard>
                                                        <CCardBody>
                                                            <CFormGroup>
                                                                <CLabel htmlFor="tipo">Tipo</CLabel>
                                                                <CSelect readOnly={true} disabled={true} name="tipo" id="tipo" value={dataCliente?.clienteAnexo[index].tipo}>
                                                                    {TipoAnexoList.getList().map(item=>(
                                                                        <option key={item.value} value={item.value}>{item.description}</option>
                                                                    ))}
                                                                </CSelect>
                                                            </CFormGroup>
                                                            <CFormGroup>
                                                                <CLabel htmlFor="arquivo">Arquivo</CLabel>
                                                                {!dataCliente?.clienteAnexo[index]?.documento &&  (<CInputFile readOnly={true} disabled={true} type="text" id="nome" name="nome"/>)}
                                                                {dataCliente?.clienteAnexo[index]?.documento && (
                                                                    <div>
                                                                        <span className="pr-3">{dataCliente?.clienteAnexo[index].nome}</span>
                                                                        <a href={generateLink(dataCliente?.clienteAnexo[index]?.documento.id)} target="_blank" download >
                                                                            <CButton className="btn-sm py-1" color="success" onClick={()=>{}}>
                                                                                <CIcon name="cil-cloud-download" className="mr-2" />Baixar
                                                                            </CButton>
                                                                        </a>
                                                                    </div>
                                                                )}
                                                            </CFormGroup>
                                                        </CCardBody>
                                                    </CCard>
                                                </CCardBody>
                                            </CCard>
                                        ))}
                                    </CTabPane>
                                    <CTabPane>
                                        <CFormGroup>
                                            <CLabel htmlFor="projetoSuporteRedmine">Projeto Suporte do Cliente no Redmine. Deve-se associar cadastros por CNPJ.</CLabel>
                                            <CSelect readOnly={true} disabled={true} name="projetoSuporteRedmine" id="projetoSuporteRedmine"  value={dataCliente?.projetoSuporteRedmine}>
                                                <option key={''} value={''}>Não identificado Projeto Associado no Redmine para Suporte do Cliente.</option>
                                                {listProjetoRedmine.map( (item : any,index)=>(
                                                    <option key={index} value={item.id}>{item.label}</option>
                                                ))}
                                            </CSelect>
                                        </CFormGroup>
                                    </CTabPane>
                                    <CTabPane className="mt-4">
                                        <div className={'row font-weight-bold text-center border-top border-bottom'}>
                                            <div className={'col border-left'}>
                                                <CLabel>#Id</CLabel>
                                            </div>
                                            <div className={'col border-left'}>
                                                <CLabel>Nome</CLabel>
                                            </div>
                                            <div className={'col border-left'}>
                                                <CLabel>Situação</CLabel>
                                            </div>
                                            <div className={'col border-left'}>
                                                <CLabel>Codigo</CLabel>
                                            </div>
                                            <div className={'col border-left'}>
                                                <CLabel>Data/Hora Contratação</CLabel>
                                            </div>
                                            <div className={'col border-left border-right'}>
                                                <CLabel>Data/Hora Finalização</CLabel>
                                            </div>
                                        </div>
                                        {dataCliente.objetosContrato && dataCliente.objetosContrato.map((item: any, index: number) => (
                                            <div className={index % 2 == 0 ? 'bg-gray-100 row text-center text-dark border-top border-bottom ' : 'row text-center border-top border-bottom'}>
                                                <div className={'col border-left'}>
                                                    <CLabel>{item?.objcon_id}</CLabel>
                                                </div>
                                                <div className={'col border-left'}>
                                                    <CLabel>{item?.objcon_nome}</CLabel>
                                                </div>
                                                <div className={'col border-left'}>
                                                    <CBadge color={item.cliobjcon_situacao == 2 ? 'danger' : 'success'}>
                                                        {SituacaoClienteObjContratoList.findSelectValue(item?.cliobjcon_situacao)?.label}
                                                    </CBadge>
                                                </div>
                                                <div className={'col border-left'}>
                                                    <CLabel>{item?.objcon_codigo}</CLabel>
                                                </div>
                                                <div className={'col border-left'}>
                                                    <CLabel>{item?.data_hora_inicio}</CLabel>
                                                </div>
                                                <div className={'col border-left border-right'}>
                                                    <CLabel>{item?.data_hora_fim}</CLabel>
                                                </div>
                                            </div>
                                        ))}
                                    </CTabPane>
                                </CTabContent>
                            </CTabs>
                        </div>
                    </div>
                </CModalBody>
            </CModal>
    );
}

export default ModalDetalhesCliente