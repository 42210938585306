import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import Grid, {ListaMultiplaFilter} from '../../../components/grid';
import { ActionProps } from '../../../components/gridAction';
import enumDescription from "../../../services/enumDescription";
import {GridApi} from "ag-grid-community";
import {SituacaoTypes} from "../../cadastros/cliente";
import {TipoCobrancaList} from "./index";
import {booleanFormater} from "../../../components/gridUtils";



const ObjetoContratoList = (props: any) => {

    const handleGridReady = (gridApi:GridApi)=>{
        const filterInstance = gridApi.getFilterInstance('situacao');
        filterInstance?.setModel({
            type: 'in',
            value: [SituacaoTypes.ATIVO, SituacaoTypes.EM_PROJETO]
        });
        gridApi.onFilterChanged();
    }

    const actions: Array<ActionProps> = [
        {
            route:'objeto-contrato/incluir',
            color:'success',
            type:'normal',
            title:'Incluir'
        },
        {
            route:'objeto-contrato/alterar',
            color:'warning',
            type:'grid',
            title:'Alterar'
        },
        {
            route:'objeto-contrato/visualizar',
            color:'info',
            type:'grid',
            title:'Visualizar',
        },
        {
            route:'objeto-contrato/delete',
            color:'danger',
            type:'delete',
            title:'Excluir',
        },
        {
            route:'/objeto-contrato/cliente',
            color:'primary',
            type:'custom',
            title:'Clientes',
        }
    ];

    return (
        <Grid actions={actions} rota="objeto-contrato/list" handleGridReady={handleGridReady} >
            <AgGridColumn headerName="#"           field={ "id" }               filter={ 'agNumberColumnFilter'}  sortable={ true } width={100} checkboxSelection/>
            <AgGridColumn headerName="Nome"        field={ "nome" }             filter={ true }                   sortable={ true } flex={1}/>
            <AgGridColumn headerName="Código"      field={ "codigo" }           filter={ true }                   sortable={ true } flex={1}/>
            <AgGridColumn headerName="Descrição"   field={ "descricao" }        filter={ true }                   sortable={ true } width={300}/>
            <AgGridColumn headerName="Valor Setup" field={ "valorSetup" }       filter={ 'number' }               sortable={ true } flex={1}/>
            <AgGridColumn headerName="Tipo"        field={ "tipoConfiguracao" } filter={ FiltroTipoConfiguracao } sortable={ true } flex={1}/>
            <AgGridColumn headerName="Ativo"       field={ "ativo" }            filter={ true }                   sortable={ true } width={100} />
        </Grid>
    );

}
class FiltroTipoConfiguracao extends ListaMultiplaFilter{
    getList(): enumDescription {
        return TipoCobrancaList;
    }
}

export default ObjetoContratoList;