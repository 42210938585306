import {ContentRoute} from "../../../models/Route";
import objetosContratosList from "./objetosContratosList";
import ObjetoContratoForm from "./objetoContratoForm";
import enumDescription from "../../../services/enumDescription";
import objetoContratoClienteList from "./objetoContratoClienteList";


export enum TipoConfigTypes {
    SEM_VALOR_MENSAL = 0,
    TABELA_COMISSAO = 1,
    SOB_DEMANDA  = 2,
    VALOR_FIXO = 3
}

export const TipoCobrancaList = new enumDescription(  [
    {   value: TipoConfigTypes.SEM_VALOR_MENSAL, description: 'Sem Valor Mensal'    },
    {   value: TipoConfigTypes.TABELA_COMISSAO, description: 'Tabela de Comissões'    },
    {   value: TipoConfigTypes.SOB_DEMANDA, description: 'Valor sob-demanda'    },
    {   value: TipoConfigTypes.VALOR_FIXO, description: 'Valor Fixo'    },
]);


export const objeto_contrato_content_routes: Array<ContentRoute> = [
    {
        'path':'/objeto-contrato',
        'description': 'Objetos de Contrato',
        'exact':true,
        'component': objetosContratosList,
        'menu': true
    },
    {
        'path':'/objeto-contrato/incluir',
        'description': 'Incluir Objeto de Contrato',
        'exact':false,
        'component': ObjetoContratoForm,
        'menu': false
    },
    {
        'path':'/objeto-contrato/alterar/:id',
        'description': 'Incluir Objeto de Contrato',
        'exact':false,
        'component': ObjetoContratoForm,
        'menu': false
    },
    {
        'path':'/objeto-contrato/visualizar/:id',
        'description': 'Incluir Objeto de Contrato',
        'exact':false,
        'component': ObjetoContratoForm,
        'menu': false
    },
    {
        'path':'/objeto-contrato/cliente/:id',
        'description': 'filtrado',
        'exact':false,
        'component': objetoContratoClienteList,
        'menu': false
    },
    {
        'path':'/objeto-contrato/cliente',
        'description': 'Clientes Objeto de Contrato',
        'exact':false,
        'component': objetoContratoClienteList,
        'menu': false
    },
]