import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import Grid, {ListaMultiplaFilter} from '../../../components/grid';
import { ActionProps } from '../../../components/gridAction';
import enumDescription from "../../../services/enumDescription";
import {GridApi} from "ag-grid-community";
import {SituacaoList, SituacaoTypes} from "../../cadastros/cliente";
import {TipoCobrancaList} from "./index";
import {booleanFormater} from "../../../components/gridUtils";
import {useLocation} from "react-router-dom";
import ClienteList from "../../cadastros/cliente/clienteList";


const ObjetoContratoClienteList = (props: any) => {

    let location = useLocation();
    let idLocation = location.pathname.split('/');
    let idObjetoContrato = parseInt(idLocation[idLocation.length - 1]);

    const situacaoListFormater  = (params:any) =>{
        return SituacaoList.getDescription(params.value);
    }

    return (
        <Grid rota={idObjetoContrato > 0 ?"objeto-contrato-cliente/list/"+idObjetoContrato : 'objeto-contrato-cliente/list'}>
            <AgGridColumn headerName="#" width={90} checkboxSelection field="id"  sortable={ true } filter={ 'agNumberColumnFilter'}></AgGridColumn>
            <AgGridColumn headerName="Nome"  flex={1}  field="pessoa/nome"  sortable={ true } filter={ true }></AgGridColumn>
            <AgGridColumn headerName="Nome Fantasia"  flex={1}  field={"pessoa/nomeFantasia"}  sortable={ true } filter={ true }></AgGridColumn>
            <AgGridColumn headerName="CPF/CNPJ" width={200} field={"pessoa/cpfCnpj"}  sortable={ true } filter={ true }></AgGridColumn>
            <AgGridColumn sort="asc" headerName="Situação" width={200} field={"situacao"}  sortable={ true } filter={ FiltroSituacao }  valueFormatter={situacaoListFormater}></AgGridColumn>
            <AgGridColumn headerName="Objeto De Contrato" width={200} field={"nomeObjetoContrato"} ></AgGridColumn>
            <AgGridColumn headerName="Data Adesão" width={200} field={"dataAdesao"} ></AgGridColumn>
            <AgGridColumn headerName="Data Finalização" width={200} field={"dataFinalizacao"} ></AgGridColumn>
        </Grid>
    );

}
class FiltroSituacao extends ListaMultiplaFilter{
    getList(): enumDescription {
        return SituacaoList;
    }
}
export default ObjetoContratoClienteList;